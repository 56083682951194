import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import Flickity from 'flickity'
import { gsap } from "gsap";
import Swup from 'swup';

window.Alpine = Alpine
Alpine.plugin(collapse)
Alpine.start()

var flkty = new Flickity( '.carousel', {});

const swup = new Swup();

gsap.to(".box", { 
    x: 200,
});
  